import React from "react";
import aboutData from '../projects-sample/aboutMe.json'
import { IntroAnimations } from './IntroAnimations'
import { Contacts } from './Contacts'
import { Projects } from "./Projects"

const Home = () => {
  const introData = aboutData.filter( myData => myData.intro )
  const contactsInfo = introData[0].contactInfo
  return (
    <div className="container w-full mx-auto">
      <div className="flex justify-center py-4 px-2 leading-relaxed lg:truncate">
      </div>
      <section className="mt-5 pt-4 pb-6">
        {introData.map( ( data, i ) =>
          <IntroAnimations key={i} intro={data.intro}
          />
        )}
      </section>
      <section>
        <Projects />
      </section>
      <section className="shadow-xl">
        <Contacts
          contactsInfo={contactsInfo}
        />
      </section>
    </div>
  )
}

export default Home