import React from 'react'
import { Link } from 'react-router-dom'
import profilLogo from '../assets/logo-small.png'
import { Toggle } from '../components/ThemeToggle'

const Navbar = () => {
  return (
    <header>
      <nav className="w-full flex items-center px-4 pt-2 mb-1">
        {/* <!-- Width of 16 by default, 32 on medium screens, and 48 on large screens --> */}
        <Link to="/" className="bg-none dark:bg-offWhite">
          <img src={profilLogo} alt={profilLogo} className="d-block mx-auto rounded-full w-16 md:w-16 lg:w-32 xl:w-32 h-16 md:h-16 lg:h-32 xl:h-32"
          />
        </Link>
        <div className="ml-auto">
          <Toggle className="px-2 nav__link" />
          <Link to="/projects" className="hover:bg-slate-900 hover:text-white px-2 nav__link">
            PROJECTS
          </Link>
        </div>
      </nav>
    </header>
  )
}

export default Navbar