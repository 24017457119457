import React, { useEffect } from "react";
import projectsData from '../projects-sample/projectsData.json'
import { useAnimation } from "framer-motion";
import { Project } from "./Project";
import { useInView } from "react-intersection-observer";

export const Projects = () => {
  const projects = projectsData.projects
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect( () => {
    if ( inView ) {
      controls.start( ( i ) => ( {
        opacity: 1,
        transition: { delay: ( i + 2 ) * 0.2 }
      } ) );
    }
  },
    [controls, inView] )

  return (
    <div className="bg-white dark:bg-LighterDark shadow-lg">
      <div className="max-w-xl mx-auto py-8 px-4 sm:pt-20 sm:pb-24 sm:px-6 lg:max-w-5xl lg:px-8">
        <h2 className="sr-only">PROJECTS</h2>
        <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 md:grid-cols-3 md:gap-x-10 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-12" ref={ref}>
          {projects.map( ( project, i ) =>
            <Project project={project} key={project._id} custom={i} controls={controls}
            />
          )}
        </div>
      </div>
    </div>
  )
}
