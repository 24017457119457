import React, { useRef, useEffect } from 'react'
import { TimelineLite, Expo } from 'gsap'
import profileImg from '../assets/me-img-3.jpeg'
import {
  SiReact,
  SiJavascript,
  SiHtml5, SiCss3,
  SiTailwindcss,
  SiSass,
  SiStyledcomponents,
  SiNodedotjs,
  SiMongodb,
  SiNetlify,
  SiGit
} from 'react-icons/si'

export const IntroAnimations = ( props ) => {
  let firstTitle = useRef( null )
  let secondTitle = useRef( null )
  let thirdTitle = useRef( null )

  let myTween = new TimelineLite()
  let infoBox = useRef( null )
  useEffect( () => {
    myTween.to( firstTitle, 0.6, {
      opacity: 1,
      delay: 0.2,
      ease: Expo.easeOut,
      x: 0,
      y: -40,
    } )
    myTween.to( secondTitle, 0.6, {
      opacity: 1,
      delay: 0.4,
      ease: Expo.easeOut,
      x: 0,
      y: -40,
    } )
    myTween.to( thirdTitle, 0.6, {
      opacity: 1,
      delay: 0.2,
      ease: Expo.easeOut,
      x: 0,
      y: -40,
    } )
    myTween.to( infoBox, 0.6, {
      opacity: 1,
      delay: 0.4,
      ease: Expo.easeOut,
      x: 0,
      y: -10,
    } )
  } )

  return (
    <div className="w-full mx-auto">
      <div className="text-center leading-snug">
        <h1 ref={el => ( firstTitle = el )}
          className="firstTitle opacity-0">
          I am zoe ali_
        </h1>
        <h1 ref={el => ( secondTitle = el )}
          className="secondTitle opacity-0">
          fullstack web developer
        </h1>
        <h1 ref={el => ( thirdTitle = el )}
          className="thirdTitle opacity-0">
          former political science graduate
        </h1>
      </div>
      <div ref={el => ( infoBox = el )}
        className="infoBox opacity-0">
        <div className="bg-white dark:bg-LighterDark text-slate-800 dark:text-slate-100 mx-auto p-4 shadow-lg">
          <div className="d-block w-32 md:w-48 lg:w-48 xl:w-52 h-32 md:h-48 lg:h-48 xl:h-52 rounded-full shadow-xl mx-auto -mt-12 bg-cover bg-center z-100 contrast-125 hover:animate-spin"
            style={{ backgroundImage: "url( " + profileImg + " )" }}>
          </div>
          <p className="p-4 sm:py-4">
            {props.intro}
          </p>
          <div className="flex flex-wrap justify-center px-4">
            <SiReact className="mr-2" />
            <SiJavascript className="mr-2" />
            <SiHtml5 className="mr-2" />
            <SiCss3 className="mr-2" />
            <SiGit className="mr-2" />
            <SiNetlify className="mr-2" />
            <SiNodedotjs className="mr-2" />
            <SiMongodb className="mr-2" />
            <SiTailwindcss className="mr-2" />
            <SiStyledcomponents className="mr-2" />
            <SiSass />
          </div>
        </div>
      </div>
    </div>
  )
}