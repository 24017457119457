import React, { useContext } from 'react'
import { ThemeContext } from '../ThemeContext'
import { ReactComponent as DarkSVG } from '../assets/ios-moon.svg'
import { ReactComponent as LightSVG } from '../assets/ios-sunny.svg'

export const Toggle = () => {
  const { theme, setTheme } = useContext( ThemeContext )
  return (
    <div className="transition duration-500 ease-in-out rounded-full inline">
      {theme === 'dark' ? (
        <LightSVG onClick={() => setTheme( theme === 'dark' ? 'light' : 'dark' )} className="text-offWhite text-2xl cursor-pointer h-6  w-6 fill-current inline-flex"
        />
      ) : (
        <DarkSVG onClick={() => setTheme( theme === 'dark' ? 'light' : 'dark' )}
          className="text-LighterDark text-2xl cursor-pointer h-6 w-6 fill-current inline-flex"
        />
      )}
    </div>
  )
}