import React from 'react'

const Footer = () => {
    return (
        <div className="w-full flex flex-wrap relative bg-offWhite dark:bg-SVTPlayBlack">
            <div className="footer absolute inset-x-0 text-center pt-6 pb-3">
                <p className="font-medium tracking-wide text-sm">
                    &copy;{new Date().getFullYear().toString()}, zoeali. all rights reserved.</p>
                <p className="text-sm">zoeecoding@gmail.com</p>
            </div>
        </div>
    )
}

export default Footer
