import React from "react"
import { motion } from "framer-motion"

export const Project = ( { project, controls, custom } ) => {
  return (
    <motion.a
      className="block p-2 text-center rounded-md bg-white dark:bg-slate-750"
      style={{
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.10)",
      }}
      whileHover="hover"
      initial="initial"
      variants={{
        initial: {
          opacity: 0,
        },
        hover: {
          y: -3,
        },
      }}
      animate={controls}
      href={project.link}
      target="_blank"
      rel="noopener noreferrer"
      custom={custom}
    >
      <div className="w-full h-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden lg:aspect-w-2 lg:aspect-h-2 xl:aspect-w-17 xl:aspect-h-18">
        <img
          src={require( `../assets/${project.image}` )} alt={project.title}
          className="w-full h-full object-fit group-hover: contrast-125"
        />
      </div>
      <h4 className="py-3 text-sm text-slate-800 dark:text-slate-100">
        {project.title}</h4>
    </motion.a>
  )
}