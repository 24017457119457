import React from 'react'
import { SiGithub, SiLinkedin, SiInstagram } from 'react-icons/si'
import { FaRegEnvelope } from 'react-icons/fa'

export const Contacts = ( { contactsInfo } ) => {
    const { email, githubLink, insta, linkedInLink
    } = contactsInfo
    return (
        <div className="w-full mt-10 shadow-lg bg-white dark:bg-LighterDark">
            <div className="py-4 text-center relative">
                <div className="">
                    <h1 className="firstTitle leading-snug">
                        Contact
                    </h1>
                    <a href={`mailto:${email}?subject=Email from website`} className="relative">
                        say hi @ zoeecoding
                        <FaRegEnvelope className="inline-flex ml-1 animate-bounce outline-none" />
                    </a>
                </div>
                <div className="py-3 mt-auto w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-center">
                    <a href={githubLink} className="p-1 mt-2 rounded-md outline-none" data-tippy-content="@zoeyzali" target="_blank" rel="noopener noreferrer">
                        <SiGithub />
                    </a>
                    <a href={insta} target="_blank" rel="noopener noreferrer"
                        className="p-1 mt-2 rounded-md outline-none">
                        <SiInstagram />
                    </a>
                    <a href={linkedInLink} target="_blank" rel="noopener noreferrer"
                        className="p-1 mt-2 rounded-md outline-none linkedIn">
                        <SiLinkedin />
                    </a>
                </div>
            </div>
            <div className="fixed-misc hover:text-slate-900">
                zoeecoding@gmail.com
            </div>
        </div >
    )
}