import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css'
import Navbar from './components/Navbar'
import Home from './components/Home'
import { Projects } from './components/Projects'
import Footer from './components/Footer'

function App() {
  return (
    <Router>
      <main>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/projects" component={Projects} />
        </Switch >
        <Footer />
      </main>
    </Router >
  )
}

export default App